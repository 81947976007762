import { click, ENTD, ENTDWindow, EventPosition, KampagnenEvent, Placement, promotion_banner, view } from "../../ENTDWindow";
import { DomainEvent } from "@cube/tracking-library-api";

declare const window: ENTDWindow;

export const trackView = (kampagneName: string, eventPosition: EventPosition, placement: Placement): void => {
    if (window.CubeDataLayer) {
        const event: KampagnenEvent = {
            event_name: "promotion_banner_view",
            responsible_team_feature: ENTD,
            responsible_team_tracking: ENTD,
            event_data: {
                event_category: promotion_banner,
                event_type: view,
                event_position: eventPosition,
                is_interaction_event: false,
                event_context: kampagneName,
            },
            promotion_data: {
                type: placement,
                name: kampagneName,
                placement: eventPosition,
                rule: "",
            },
        };
        window.CubeDataLayer.push(event as DomainEvent);
    }
};

export const trackDetailClick = (kampagneName: string, eventPosition: EventPosition, placement: Placement): void => {
    if (window.CubeDataLayer) {
        const event: KampagnenEvent = {
            event_name: "promotion_banner_detail",
            responsible_team_feature: ENTD,
            responsible_team_tracking: ENTD,
            event_data: {
                event_category: promotion_banner,
                event_type: click,
                event_position: eventPosition,
                is_interaction_event: true,
                event_context: kampagneName,
            },
            promotion_data: {
                type: placement,
                name: kampagneName,
                placement: eventPosition,
                rule: "",
            },
        };
        window.CubeDataLayer.push(event as DomainEvent);
    }
};

export const trackLinkClick = (kampagneName: string, url: string, eventPosition: EventPosition, placement: Placement, elementPosition?: number): void => {
    if (window.CubeDataLayer) {
        const event: KampagnenEvent = {
            event_name: "promotion_link",
            responsible_team_feature: ENTD,
            responsible_team_tracking: ENTD,
            event_data: {
                event_category: promotion_banner,
                event_type: click,
                event_position: eventPosition,
                is_interaction_event: true,
                event_context: kampagneName,
                event_content: [url],
            },
            promotion_data: {
                type: placement,
                name: kampagneName,
                placement: eventPosition,
                element_position: elementPosition ? elementPosition : 1,
                rule: "",
            },
        };
        window.CubeDataLayer.push(event as DomainEvent);
    }
};
