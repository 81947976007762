import "../styles/footer.scss";
import * as CubeTrackingAB from "./tracking/cube/AbTestTracking";
import * as CubeTrackingAK from "./tracking/cube/AktionskommunikationsTracking";
import { footer } from "./ENTDWindow";
import * as GtmTrackingAB from "./tracking/gtm/AbTestTracking";

class Footer extends HTMLElement {
    private observer: IntersectionObserver;
    private alreadyTracked: boolean;

    constructor() {
        super();
        this.observer = new IntersectionObserver(this.handleObservationEvent.bind(this), { threshold: 0.9 });
        this.alreadyTracked = false;
    }

    connectedCallback(): void {
        this.observer.observe(this);
    }

    private handleObservationEvent(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting && !this.alreadyTracked) {
                const el = this.querySelector<HTMLDivElement>('[data-select="footer"]');
                const kampagneName = el?.getAttribute("data-kampagne-name");
                const abTestHeaderName = el?.getAttribute("data-abtest-name");
                const abTestHeaderValue = el?.getAttribute("data-abtest-value");
                CubeTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                kampagneName ? CubeTrackingAK.trackView(kampagneName, footer, footer) : null;
                GtmTrackingAB.trackView(abTestHeaderValue, abTestHeaderName);
                this.alreadyTracked = true;
            }
        });
    }

    disconnectedCallback(): void {
        this.observer.disconnect();
    }
}

customElements.define("entd-footer", Footer);
